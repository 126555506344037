import React from "react";
import HeaderLogo from "./assets/images/logo-menu.png";
import HeaderGif from "./assets/images/header-gif.gif";
import HeaderTitle from "./assets/images/header-title.png";
import AboutTitle from "./assets/images/about-title.png";
import About1 from "./assets/images/about-1.png";
import About2 from "./assets/images/about-2.png";
import About3 from "./assets/images/about-3.png";
import About4 from "./assets/images/about-4.png";
import About5 from "./assets/images/about-5.png";
import About6 from "./assets/images/about-6.png";
import About7 from "./assets/images/about-7.png";
import About8 from "./assets/images/about-8.png";
import Dt from "./assets/images/dt.png";
import Dexs from "./assets/images/dexs.png";
import Telegram from "./assets/images/telegram.png";
import Hand from "./assets/images/hand.png";
import X from "./assets/images/x.png";

import "./App.css";

const App = () => {
  return (
    <div className="bg-[#EEF2F3] min-h-screen text-center">
      <header className="min-h-screen bg-cover bg-center p-4 bg-header">
        <div className="bg-[#035E8D] p-3 rounded-full max-w-7xl mx-auto flex justify-between items-center uppercase">
          <div className="w-[68px] h-[68px]">
            <img src={HeaderLogo} alt="header-logo" />
          </div>
          <div className="flex items-center gap-5">
            <a
              href="https://x.com/GhibliHakuBSC"
              target="_blank"
              rel="noreferrer noopener"
              className="transition-all flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 42 40"
                fill="none"
              >
                <path
                  d="M0.102198 0L16.3179 22.0623L0 40H3.6728L17.9594 24.2949L29.5022 40H42L24.8716 16.697L40.0603 0H36.3875L23.2307 14.4636L12.6 0H0.102198ZM5.5032 2.75259H11.2446L36.5983 37.2474H30.8569L5.5032 2.75259Z"
                  fill="#EBF2FA"
                />
              </svg>
              <span className="hidden lg:block">Twitter (X)</span>
            </a>
            <a
              href="https://t.me/GhibliHaku"
              target="_blank"
              rel="noreferrer noopener"
              className="transition-all flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="40"
                viewBox="0 0 39 40"
                fill="none"
              >
                <path
                  d="M2.68261 17.1597L25.7285 7.66439C28.0034 6.67529 35.7184 3.51019 35.7184 3.51019C35.7184 3.51019 39.2791 2.12546 38.9824 5.48838C38.8835 6.87311 38.0922 11.7197 37.3009 16.9619L34.8282 32.4907C34.8282 32.4907 34.6304 34.7656 32.9489 35.1612C31.2675 35.5569 28.498 33.7765 28.0034 33.3809C27.6078 33.0841 20.5852 28.6332 18.0136 26.4572C17.3212 25.8637 16.5299 24.6768 18.1125 23.2921C21.6732 20.0281 25.9263 15.9728 28.498 13.4011C29.6849 12.2142 30.8718 9.44476 25.9263 12.8077L11.9801 22.2041C11.9801 22.2041 10.3976 23.1932 7.43027 22.303C4.46298 21.4128 1.00115 20.2259 1.00115 20.2259C1.00115 20.2259 -1.37268 18.7422 2.68261 17.1597Z"
                  fill="#EBF2FA"
                />
              </svg>
              <span className="hidden lg:block">Telegram</span>
            </a>
          </div>
          <button className="bg-[#EBF2FA] text-[#035E8D] px-4 py-1 rounded-full uppercase transition-all hover:opacity-80">
            Buy $HAKU
          </button>
        </div>

        <div className="mt-16">
          <div className="w-[203px] mx-auto">
            <img src={HeaderTitle} alt="" />
          </div>

          <div className="max-w-[461px] mx-auto mt-6 border-solid border-8 border-[#035E8D]">
            <img src={HeaderGif} alt="" />
          </div>

          <div className="mt-6">
            <p className="w-full max-w-xl bg-white border-solid border-8 border-[#035E8D] rounded-full px-6 py-2 inline-block shadow text-[#00273C]">
              CA: TBA
            </p>
          </div>
          <div className="flex justify-center gap-4 my-6">
            <button className="bg-[#035E8D] px-8 py-2 rounded-3xl transition-all hover:opacity-80">
              CHART
            </button>
            <button className="bg-[#035E8D] px-8 py-2 rounded-3xl transition-all hover:opacity-80">
              BUY $HAKU
            </button>
          </div>
        </div>
      </header>

      <main className="pt-24">
        <section className="container mx-auto px-4">
          <div className="w-[228px] mx-auto">
            <img src={AboutTitle} alt="" />
          </div>
          <p className="max-w-4xl mx-auto text-[#00273C] mt-10">
            Haku is a key character in Spirited Away by Studio Ghibli. He is a
            river spirit who appears as a young boy, guiding and protecting
            Chihiro in the spirit world. Haku’s journey to reclaim his true name
            symbolizes themes of memory, identity, and growth in the film.
          </p>

          <div className="mt-8 columns-1 md:columns-2 lg:columns-4 gap-4 space-y-4">
            <img src={About1} alt="" />
            <img src={About5} alt="" />
            <img src={About2} alt="" />
            <img src={About6} alt="" />
            <img src={About3} alt="" />
            <img src={About7} alt="" />
            <img src={About4} alt="" />
            <img src={About8} alt="" />
          </div>
        </section>

        <section className="container mx-auto px-4 pt-16">
          <div className="flex justify-center items-center gap-5">
            <a
              href="https://x.com/GhibliHakuBSC"
              target="_blank"
              rel="noreferrer noopener"
              className="w-[44px] h-[44px]"
            >
              <img src={X} alt="" />
            </a>
            <a
              href="https://t.me/GhibliHaku"
              target="_blank"
              rel="noreferrer noopener"
              className="w-[44px] h-[44px]"
            >
              <img src={Telegram} alt="" />
            </a>
            <a className="w-[44px] h-[44px]">
              <img src={Hand} alt="" />
            </a>
            <a className="w-[44px] h-[44px]">
              <img src={Dt} alt="" />
            </a>
            <a className="w-[44px] h-[44px]">
              <img src={Dexs} alt="" />
            </a>
          </div>
        </section>
      </main>

      <footer className="text-center py-3 mt-5">
        <p className="text-[#035E8D] uppercase">
          2025 Haku. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default App;
